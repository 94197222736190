import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import GoogleMapReact from "google-map-react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import Facilities from "../components/facilities"
import BookingForm from "../components/bookingform"
import Testimonial from "../components/Testimonial"
import "../style/singlepage.css"

const AnyReactComponent = () => (
  <a
    href="https://goo.gl/maps/6UwecwgKdbrygtws9"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div
      style={{
        position: "absolute",
        top: "-80px",
        left: "-50px",
        width: "160px",
      }}
    >
      {" "}
      <img
        width="100"
        src="https://res.cloudinary.com/dzudmfllt/image/upload/v1587362445/weHotel/iconfinder.png"
        alt="iconfinder"
      />
      <p
        style={{
          fontSize: "22px",
          marginLeft: "-36px",
          fontFamily: "Cabin, sans-serif",
          color: "#3e3e3e",
        }}
      >
        We Hotel Langkawi
      </p>
    </div>
  </a>
)

const GoogleMap = () => {
  const center = {
    lat: 6.321916,
    lng: 99.84954,
  }
  const zoom = 17

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "500px", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDoWXsL5JpW3pIOcMSxccbJDaKY9sAK0rs" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <AnyReactComponent lat={6.321916} lng={99.847564} />
      </GoogleMapReact>
    </div>
  )
}

const Langkawi = () => {
  const data = useStaticQuery(graphql`
    query Images {
      image: file(relativePath: { eq: "langkawi/whlangkawi.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      background: file(relativePath: { eq: "beach.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      rooms: allFile(filter: { relativeDirectory: { eq: "langkawi/rooms" }}, sort: {fields: name}) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      discover: allFile(
        filter: { relativeDirectory: { eq: "langkawi/discover" }}, sort: {fields: name}) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      promos: allFile(
        filter: { relativeDirectory: { eq: "langkawi/promo" } }
        limit: 3
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const roomDetails = [
    {
      name: "Standard Room",
      fprice: 196,
      dprice: 150,
      size: 98,
      capacity: 2,
      facilities: [
        {
          id: 1,
          name: "1 Queen Size Bed",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Flat Screen TV",
        },
        {
          id: 4,
          name: "Air-conditioning",
        },
      ],
    },
    {
      name: "Standard Twin Room",
      fprice: 196,
      dprice: 150,
      size: 127,
      capacity: 2,
      facilities: [
        {
          id: 1,
          name: "2 Single Beds",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Flat Screen TV",
        },
        {
          id: 4,
          name: "Air-conditioning",
        },
      ],
    },
    {
      name: "Family Triple Room",
      fprice: 252,
      dprice: 220,
      size: 180,
      capacity: 3,
      facilities: [
        {
          id: 1,
          name: "1 Queen Size Bed + 1 Single Bed",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Flat Screen TV",
        },
        {
          id: 4,
          name: "Air-conditioning",
        },
        {
          id: 5,
          name: "Mini Fridge & Iron",
        }
      ],
    },
    {
      name: "Family Quad Room",
      fprice: 336,
      dprice: 320,
      size: 232,
      capacity: 4,
      facilities: [
        {
          id: 1,
          name: "2 Queen Size Beds",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Flat Screen TV",
        },
        {
          id: 4,
          name: "Air-conditioning",
        },
        {
          id: 5,
          name: "Mini Fridge & Iron",
        }
      ],
    },
    {
      name: "Family Executive Room",
      fprice: 432,
      dprice: 420,
      size: 274,
      capacity: 4,
      facilities: [
        {
          id: 1,
          name: "2 King Size Beds",
        },
        {
          id: 2,
          name: "Flat Screen TV",
        },
        {
          id: 3,
          name: "Air-conditioning",
        },
        {
          id: 4,
          name: "Mini Fridge & Iron",
        },
        {
          id: 5,
          name: "Sitting Area",
        },
        {
          id: 6,
          name: "Coffee/Tea Making Facilities",
        }
      ],
    },
    {
      name: "Executive Room",
      fprice: 336,
      dprice: 220,
      size: 231,
      capacity: 2,
      facilities: [
        {
          id: 1,
          name: "1 King Size Bed",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Flat Screen TV",
        },
        {
          id: 4,
          name: "Air-conditioning",
        },
        {
          id: 5,
          name: "Sitting Area",
        },
        {
          id: 6,
          name: "Mini Fridge & Iron",
        },
        {
          id: 7,
          name: "Coffee/Tea Making Facilities",
        }
      ],
    },
    {
      name: "Suite Room",
      fprice: 468,
      dprice: 450,
      size: 319,
      capacity: 2,
      facilities: [
        {
          id: 1,
          name: "1 King Size Bed",
        },
        {
          id: 2,
          name: "Hot Shower Bathroom",
        },
        {
          id: 3,
          name: "Jacuzzi",
        },
        {
          id: 4,
          name: "Flat Screen TV",
        },
        {
          id: 5,
          name: "Air-conditioning",
        },
        {
          id: 6,
          name: "Mini Fridge & Iron",
        },
        
        {
          id: 7,
          name: "Sitting Area",
        },
        {
          id: 8,
          name: "Safety Box",
        },
        {
          id: 9,
          name: "Coffee/Tea Making Facilities",
        }
        
      ],
    },
  ]

  const itemDetails = [
    {
      name: "Dataran Lang, Pekan Kuah",
      link: "https://naturallylangkawi.my/portfolio/dataran-lang-eagle-square/",
    },
    {
      name: "Taman Lagenda, Langkawi",
      link: "https://naturallylangkawi.my/portfolio/lagenda-park/",
    },
    {
      name: "Sunset Cruise",
      link:
        "https://www.tripadvisor.com.my/Attraction_Review-g298283-d6556722-Reviews-Sunset_Dinner_Cruise_Langkawi-Langkawi_Langkawi_District_Kedah.html",
    },
    {
      name: "Galeria Perdana",
      link: "http://www.jmm.gov.my/en/museum/galeria-perdana",
    },
    {
      name: "Pantai Cenang, Langkawi",
      link:
        "https://www.tripadvisor.com.my/Attraction_Review-g1096282-d450982-Reviews-Cenang_Beach-Pantai_Cenang_Langkawi_Langkawi_District_Kedah.html",
    },
  ]

  let rooms = data.rooms.nodes
  rooms.forEach((room, i) => {
    room.name = ""
    room.fprice = ""
    room.dprice = ""
    room.size = ""
    room.capacity = ""
    room.facilities = []
    i += 0

    room.name = roomDetails[i].name
    room.fprice = roomDetails[i].fprice
    room.dprice = roomDetails[i].dprice
    room.size = roomDetails[i].size
    room.capacity = roomDetails[i].capacity
    room.facilities = roomDetails[i].facilities
  })

  let discovers = data.discover.nodes
  discovers.forEach((discover, i) => {
    discover.name = ""
    discover.link = ""
    i += 0

    discover.name = itemDetails[i].name
    discover.link = itemDetails[i].link
  })

  return (
    <Layout>
      <SEO title="We Hotel Langkawi" />
      <Img
        fluid={data.image.childImageSharp.fluid}
        alt="We Hotel Langkawi"
        draggable={false}
      />

      <div className="intro-text">
        <h1 className="heading">
          We Hotel Langkawi offers elegant guest rooms with panoramic sea and
          hill views.
        </h1>
        <p style={{ letterSpacing: "0.05em" }}>
          We Hotel Langkawi offers elegant guest rooms with panoramic sea and
          hill views. With the iconic MahaTower, commercial attractions and
          strategic location in Kuah town, Langkawi City is set to become the
          most visited site in Langkawi.
        </p>
      </div>

      <div className="rooms">
        <div className="box-container">
          {rooms.map(room => (
            <div key={room.id} className="box">
              <Img fluid={room.childImageSharp.fluid} />
              <div className="box-detail">
                <h3
                  style={{
                    fontWeight: "300",
                    fontSize: "28px",
                  }}
                >
                  {room.name}
                </h3>
                <p>
                  <span
                    style={{
                      fontSize: "36px",
                      color: "#FF5252",
                    }}
                  >
                    RM{room.dprice}
                  </span>
                  <span
                    style={{
                      textDecoration: "line-through",
                      color: "rgb(250, 143, 143)",
                      fontSize: "18px",
                    }}
                  >
                    (RM{room.fprice})
                  </span>
                  &nbsp;/ per night
                </p>
                <p style={{ fontSize: "16px", marginTop: "-13px" }}>
                  Size{" "}
                  <span className="facilities-details">
                    {room.size} sq. ft.
                  </span>{" "}
                  <span style={{ padding: "0 3px" }}></span> Capacity{" "}
                  <span className="facilities-details">
                    {room.capacity} persons
                  </span>{" "}
                  <br />
                  <br />
                  <span className="facilities-title">Facilities</span>{" "}
                  <ul className="facility-list">
                    {room.facilities.map(facility => (
                      <li key={facility.id}>{facility.name}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
          ))}
          <div className="box-booking">
          <a href="#form"><button className="mobile-button">Book Now</button></a>
          </div>
        </div>
      </div>

      <div className="discover">
        <h1>Discover Langkawi</h1>
        <div className="box-container-alt">
          {discovers.map(item => (
            <div key={item.id} className="box">
              <div>
                <Img fluid={item.childImageSharp.fluid} />
                <div className="discover-desc">
                  <a href={item.link} target="_blank" rel="noopener noreferrer">
                    <h3>{item.name}</h3>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <p>More places you can visit in a day if you stay in We Hotel Langkawi.
 Save your time, more experience.</p>
      </div>

      {/* CTA */}
      <div className="cta">
        <div className="cta-text">
          <h4 className="heading">
            We Hotel Langkawi strategic location enables guests easy access to
            countless attractions in Langkawi, all within walking distance from
            the hotel.
          </h4>
        </div>

        <div className="cta-button">
          <a href="#form">
            <div className="button">Book Now</div>
          </a>
        </div>
      </div>

      <div className="promotion">
        <BackgroundImage
          Tag="section"
          fluid={data.background.childImageSharp.fluid}
          backgroundColor={`#040e18`}
        >
          <div style={{ width: "100vw" }}>
            <h1
              style={{
                textAlign: "center",
                color: "#ffffff",
                paddingTop: "0.45em",
                marginBottom: "0",
              }}
            >
              Offers
            </h1>
            <div className="box-container">
              {data.promos.nodes.map(promo => (
                <div key={promo.id} className="box">
                  <Img fluid={promo.childImageSharp.fluid} />
                </div>
              ))}
            </div>
          </div>
        </BackgroundImage>
      </div>

      <Facilities />

      <Testimonial />

      <div className="form-wrapper">
        <div className="form-layer">
          <Img
            fluid={data.background.childImageSharp.fluid}
            durationFadeIn={1500}
            alt="langkawi beach background"
            style={{ filter: "blur(3px)", transform: "scale(1.2)" }}
          />
        </div>

        <div id="form" className="landing background-tint">
          <BookingForm rooms={roomDetails} />
        </div>
      </div>

      <div className="map">
        <GoogleMap />
      </div>
    </Layout>
  )
}

export default Langkawi
